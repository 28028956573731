'use client';

import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import React, { forwardRef, useMemo } from 'react';

import { Icon } from '@/components/icon';
import {
  MenuItemGroupType,
  MenuItemType,
  MenuNameType,
} from '@/config-schema/navigation';
import { useFlagsReady } from '@/hooks/flags-ready/use-flags-ready';
import useLoyaltyPrograms from '@/hooks/loyalty-programs/use-loyalty-programs';
import { Link } from '@/i18n/navigation';
import { cn } from '@/utils/tailwind';

export interface NavigationMenuItemGtmProps {
  event: 'homepage_navigation_menu_click' | 'homepage_v2_navigation_menu_click';
  group_0: MenuItemGroupType;
  type_0: MenuNameType;
}

// CSS variables
// --menu-item--color
// --menu-item--hover-color
// --menu-item--hover-bg-color
// --menu-item--icon-color
// --menu-item--description-color

type MenuItemProps = {
  menuItem: MenuItemType;
  className?: string;
  displayMode?: 'full' | 'simple' | 'simple-with-icon';
  titleClassName?: string;
  descriptionClassName?: string;
  dataGtm?: string;
  iconClassName?: string;
};

export const NavigationMenuItem = forwardRef<
  React.ElementRef<typeof Link>,
  React.ComponentPropsWithoutRef<typeof Link> & MenuItemProps
>((props) => {
  return (
    <NavigationMenu.Item>
      <NavigationMenu.Link asChild>
        <MenuItem {...props} />
      </NavigationMenu.Link>
    </NavigationMenu.Item>
  );
});

export const MenuItem = forwardRef<
  React.ElementRef<typeof Link>,
  React.ComponentPropsWithoutRef<typeof Link> & MenuItemProps
>((props, ref) => {
  const {
    className,
    menuItem,
    displayMode,
    titleClassName,
    descriptionClassName,
    iconClassName,
    href,
    dataGtm,
    ...restProps
  } = props;
  const m2020ListingRedirect = useFlagsReady('m2020_listing_redirect');
  let internalHref = href;

  const { loyaltyPrograms: carbonLoyaltyPrograms } = useLoyaltyPrograms({
    sortBy: 'default',
    loyaltyProgramType: 'sustainability',
  });

  const { loyaltyPrograms: cryptoLoyaltyPrograms } = useLoyaltyPrograms({
    sortBy: 'default',
    loyaltyProgramType: 'crypto',
  });

  if (m2020ListingRedirect) {
    if (
      href === '/products/crypto' &&
      cryptoLoyaltyPrograms?.data.length === 1
    ) {
      internalHref = `/products/crypto/${cryptoLoyaltyPrograms.data[0].id}`;
    }

    if (
      href === '/products/sustainability' &&
      carbonLoyaltyPrograms?.data.length === 1
    ) {
      internalHref = `/products/sustainability/${carbonLoyaltyPrograms.data[0].id}`;
    }
  }

  const isExternalUrl = `${internalHref}`?.startsWith('http');

  const menuItemComponent = useMemo(() => {
    switch (displayMode) {
      case 'full': {
        return (
          <>
            <span className="grow text-[var(--rc-menu-item--description-color)]">
              <strong className={cn('block', titleClassName)}>
                {menuItem.translation}
              </strong>
              <span className={cn('mt-1 block', descriptionClassName)}>
                {menuItem.shortDescription}
              </span>
            </span>

            {menuItem.iconVisible && menuItem.iconName ? (
              <Icon
                href={menuItem.iconHref}
                name={menuItem.iconName}
                className={cn(
                  'mt-1 h-4 w-4 flex-shrink-0',
                  'self-baseline',
                  'fill-[var(--rc-menu-item--icon-fill-color)]',
                  iconClassName,
                )}
              />
            ) : null}
          </>
        );
      }
      case 'simple-with-icon': {
        return (
          <>
            {menuItem.iconVisible && menuItem.iconName ? (
              <Icon
                href={menuItem.iconHref}
                name={menuItem.iconName}
                className={cn(
                  'mt-1 h-4 w-4 flex-shrink-0',
                  'self-baseline',
                  'fill-[var(--rc-menu-item--icon-fill-color)]',
                  iconClassName,
                )}
              />
            ) : null}
            {menuItem.translation}
          </>
        );
      }
      default: {
        return menuItem.translation;
      }
    }
  }, [displayMode, menuItem, titleClassName, descriptionClassName]);

  return (
    <Link
      className={cn(
        'flex flex-row-reverse gap-4 rounded px-6 py-4',
        'text-[var(--rc-menu-item--color)]',
        'hover:text-[var(--rc-menu-item--hover-color)]',
        'hover:bg-[var(--rc-menu-item--hover-bg-color)]',
        className,
      )}
      data-gtm={dataGtm}
      {...restProps}
      href={internalHref}
      ref={ref}
      target={isExternalUrl ? '_blank' : '_self'}
      rel={isExternalUrl ? 'noopener noreferrer' : undefined}
    >
      {menuItemComponent}
    </Link>
  );
});

MenuItem.displayName = 'MenuItem';
NavigationMenuItem.displayName = 'NavigationMenuItem';
