'use client';

import { useTranslations } from 'next-intl';

import { useHermes } from '@/auth/use-hermes';
import { RewardsCentralConfiguration } from '@/config-schema/rewards-central-config';
import { Button } from '@/design-system-components/button/button';
import { isPointsActivityEnabled } from '@/features/rewards-summary/shared/utils';
import { useTenantHubConfig } from '@/hooks/config/tenants/use-tenanthub-config';
import { useFeatureConfig } from '@/hooks/config/use-feature-config';
import { usePointsAccounts } from '@/hooks/points-accounts/use-points-accounts';
import { usePointsFormatter } from '@/hooks/utils/use-points-formatter';
import { useIsFailurePage } from '@/utils/page-url';
import { cn } from '@/utils/tailwind';

import { Container } from '../container';
import { Icon } from '../icon';
import { Logo } from '../logo';
import { RcLink } from '../rc-link';
import { HeaderNavigationV2 } from './header-navigation-v2';
import { MobileHeaderV2 } from './mobile-header-v2';

export function HeaderV2(
  props: RewardsCentralConfiguration['application']['components']['header'] &
    RewardsCentralConfiguration['application']['theme'] & {
      features: RewardsCentralConfiguration['application']['features'];
      navigationConfiguration: RewardsCentralConfiguration['application']['components']['navigation'];
    },
) {
  const { loggedIn, logout } = useHermes();
  const { data: pointsAccount, isLoading: isPointsBalanceLoading } =
    usePointsAccounts();
  const pointsFormatter = usePointsFormatter();
  const t = useTranslations('headerV2');
  const isFailurePage = useIsFailurePage();

  const tenantHubConfig = useTenantHubConfig();

  const featureConfig = useFeatureConfig('rewardsSummary');
  const isPointsActivityEnabledFlag = isPointsActivityEnabled(featureConfig);

  const brandLogo = tenantHubConfig?.logo_url ?? props.brandLogo;
  const backgroundColor =
    tenantHubConfig?.header_color ?? props.backgroundColor;

  const logoutText = t('logout');

  return (
    <header
      className={cn('header sticky top-0 z-20 flex w-full border-neutral-400', {
        hidden: isFailurePage,
      })}
      style={{
        background: backgroundColor,
        color: props.color,
      }}
    >
      <Container className="h-[72px] content-center">
        <div className="hidden w-full items-center gap-x-12 lg:flex">
          <Logo data-testid="header-logo" {...{ ...props, brandLogo }} />
          <HeaderNavigationV2 {...props} />

          <div className="ml-auto flex h-full items-center gap-x-8">
            {loggedIn ? (
              <div className="flex h-full items-center justify-center">
                {isPointsBalanceLoading ? (
                  <PointsBalanceSkeleton />
                ) : (
                  <RcLink
                    href="/rewards-summary/points-activity"
                    onClick={(e) => {
                      if (!isPointsActivityEnabledFlag) {
                        e.preventDefault();
                      }
                    }}
                    data-testid="header-points-balance"
                    className={cn('flex items-center justify-center gap-2', {
                      'cursor-default hover:text-header':
                        !isPointsActivityEnabledFlag,
                    })}
                  >
                    <Icon name="gem" />
                    <span>{pointsFormatter(pointsAccount?.pointsBalance)}</span>
                  </RcLink>
                )}

                <div
                  className="mx-8 h-8 border-l border-solid"
                  style={{ borderColor: props.color, opacity: 0.25 }}
                />

                <Button
                  data-testid="header-avatar-dropdown-logout"
                  variant="noBackground"
                  textCenter
                  className="p-0 font-normal hover:!text-secondary"
                  onPress={() => {
                    logout?.();
                  }}
                  style={{
                    color: props.color,
                  }}
                >
                  {logoutText}
                  <Icon
                    name="arrow-right-to-bracket"
                    className="ml-2 h-4 w-4"
                    style={{ fill: props.color }}
                  />
                </Button>
              </div>
            ) : null}
          </div>
        </div>

        <div className="flex w-full items-center justify-between lg:hidden">
          <Logo
            data-testid="header-logo"
            {...{ ...props, brandLogo }}
            className="ml-0"
          />
          <MobileHeaderV2 {...props} />
        </div>
      </Container>
    </header>
  );
}

export function PointsBalanceSkeleton() {
  return <div className="h-6 w-32 animate-pulse rounded bg-neutral-300" />;
}
